import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { Chevron } from '~/shared/components/Chevron/Chevron';
import Checkmark from '$icons/checkmark.svg';
import Flex from '~/shared/components/Flex';
import { Button } from '~/shared/components';

export const StyledChevron = styled(Chevron)(({ theme }) => ({
    width: 24,
    height: 24,
    color: theme.colors.brownMedium,
}));

export const StyledCheckmark = styled(Checkmark)(() => ({
    height: '12px !important',
    width: '12px !important',
    alignSelf: 'center',
}));

export const StyledCheckmarkContainer = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.colors.grey100}`,
    minWidth: '18px',
    height: '18px',
    borderRadius: theme.space[1],
}));

export const DropdownItemContainer = styled(Flex)(({ theme }) => ({
    ':not(:last-child)': {
        borderBottom: `1px solid ${theme.colors.brownLight}`,
    },
}));

export const DropdownToggleItemContainer = styled.div(({ theme }) => ({
    padding: theme.space[3],
}));

export const DropdownInputField = styled.input(({ theme }) => ({
    ...theme.mixins.useTextStyle('bodySmall'),
    borderRadius: theme.sizes.cornerRadius,
    border: `1px solid ${theme.colors.grey100}`,
    height: 40,
    paddingLeft: theme.space[3],
    paddingRight: theme.space[3],
    margin: `${theme.space[3]}`,

    '::placeholder': {
        color: theme.colors.grey50,
    },
    ':focus-visible': {
        boxShadow: theme.shadows.accessibility,
    },
}));

export const DropDownItemButton = styled(Button)<{ isRefined: boolean }>(
    ({ theme }) => ({
        padding: `${theme.space[3]} !important`,
        minHeight: 'inherit',

        '&:hover': {
            background: theme.colors.grey10,
        },
    }),
    ifProp('isRefined', ({ theme }) => ({
        background: theme.colors.brownUltraLight,
    })),
);

export const DropdownItemText = styled(Flex)(({ theme }) => ({
    ...theme.mixins.useTextStyle('bodySmall'),
    textAlign: 'center',
    justifyContent: 'space-between',
    gap: theme.space[3],
}));

export const DropdownTextWrapper = styled.div(() => ({
    width: '110px',
    hyphens: 'auto',
    wordBreak: 'break-word',
    textAlign: 'left',
}));

export const DropdownValueWrapper = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
}));

export const FilterList = styled(Flex)(() => ({
    overflowY: 'auto',
}));

export const RefinementContainer = styled.div(() => ({
    position: 'relative',
}));

export const FilterDropdown = styled.div<{ isOpen: boolean; align?: 'left' | 'right' }>(
    ({ theme, isOpen, align = 'left' }) => ({
        position: 'absolute',
        top: `calc(100% + ${isOpen ? theme.space[2] : theme.space[1]})`,
        [align]: 0,
        minWidth: 150,
        maxWidth: 350,
        maxHeight: 450,
        border: `1px solid ${theme.colors.brownLight}`,
        borderRadius: theme.sizes.cornerRadius,
        boxShadow: isOpen ? theme.shadows.floating : 'none',
        visibility: isOpen ? 'visible' : 'hidden',
        opacity: isOpen ? '1' : '0',
        background: theme.colors.white,
        zIndex: theme.zIndices['1'],
        overflowY: 'auto',
        transition: isOpen
            ? 'opacity 0.1s, top 0.2s, box-shadow 0.2s, visibility 0s'
            : 'opacity 0.1s 0.1s, top 0.2s, box-shadow 0.2s, visibility 0.2s',
    }),
);
