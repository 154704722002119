import { breakpoints } from '~/theme/breakpoints';
import styled from '@emotion/styled';
import { rgba } from 'color2k';
import { ifProp } from 'styled-tools';

type StyledDrawerContainerProps = {
    open: boolean;
};
type StyledDrawerProps = StyledDrawerContainerProps & {
    isInfo?: boolean;
    enableVerticalSlideMobile?: boolean;
};
type StyledBackdropProps = StyledDrawerProps;

export const StyledDrawerContainer = styled.div<StyledDrawerContainerProps>(({ open }) => ({
    pointerEvents: open ? 'auto' : 'none',
    visibility: open ? 'visible' : 'hidden',
}));

const getTransform = (isInfo?: boolean, enableVerticalSlideMobile?: boolean) => {
    if (isInfo) {
        return {
            transform: ['translate(0,414px)', 'translate3d(414px,0,0)'],
            opacity: 0,
        };
    }

    if (enableVerticalSlideMobile) {
        return {
            opacity: 0,
            transform: ['translate(0,200px)', 'translate3d(0,200px,0)'],
            WebkitTransform: ['translate(0,200px)', 'translate3d(0,200px,0)'],
            [breakpoints.sm]: {
                transform: ['translate(0,414px)', 'translate3d(414px,0,0)'],
                WebkitTransform: ['translate(0,414px)', 'translate3d(414px,0,0)'],
            },
        };
    }

    return {
        transform: ['translate(-200px,0)', 'translate3d(-200px,0,0)'],
        opacity: 0,
    };
};

export const StyledDrawer = styled.div<StyledDrawerProps>(
    ({ theme }) => ({
        zIndex: theme.zIndices[3],
        position: 'fixed',
        width: ['100%'],
        top: 0, // Height of the menu bar
        bottom: 0,
        left: 0,
        background: theme.colors.grey20,
        scrollBehavior: 'smooth',
        overflowY: 'auto',
        transitionDuration: '150ms',
        transitionTimingFunction: theme.easings.smoothEase,
        transitionProperty: 'transform opacity',
    }),

    ifProp(
        'open',
        {
            transform: ['translate(0, 0)', 'translate3d(0,0,0)'],
            opacity: 1,
        },
        ({ isInfo, enableVerticalSlideMobile }) => getTransform(isInfo, enableVerticalSlideMobile),
    ),

    ifProp('isInfo', ({ theme }) => ({
        maxWidth: 414,
        background: theme.colors.black,
        left: 'auto',
        right: 0,
        zIndex: theme.zIndices[4],

        [breakpoints.xs]: {
            maxWidth: '100%',
            left: 0,
        },
    })),
    ifProp('enableVerticalSlideMobile', ({ theme }) => ({
        background: theme.colors.black,

        right: 0,
        zIndex: theme.zIndices[4],
        transitionDuration: '300ms',
        WebkitTransitionDuration: '300ms',
        maxWidth: '100%',
        left: 0,
        top: 'unset',
        height: '90%',
        borderRadius: '6px 6px 0 0',

        [breakpoints.sm]: {
            maxWidth: 414,
            left: 'auto',
            height: '100%',
            borderRadius: 'unset',
        },
    })),
);

export const StyledBackdrop = styled.div<StyledBackdropProps>(
    ({ open, theme }) => ({
        display: open ? 'block' : 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: theme.zIndices[1],
    }),

    ifProp('isInfo', ({ theme }) => ({
        background: rgba(0, 0, 0, 0.4),
        backdropFilter: 'blur(10px)',
        zIndex: theme.zIndices[3],
    })),

    ifProp('enableVerticalSlideMobile', ({ theme }) => ({
        background: rgba(0, 0, 0, 0.4),
        backdropFilter: 'blur(10px)',
        zIndex: theme.zIndices[3],
    })),
);

export const StyledContent = styled.div(({ theme }) => ({
    height: '100%',
    background: theme.colors.white,
}));
