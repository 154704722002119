import React, { useState, FC } from 'react';
import styled from '@emotion/styled';
import { mq } from '~/lib/helpers/media-query.helper';
import { useRange, UseRangeProps } from 'react-instantsearch';
import { useDebounce } from 'react-use';

const unsetNumberInputValue = '';

const RefinementPriceForm = styled.form<{ hasValue: boolean }>(({ hasValue, theme }) => ({
    minWidth: '280px',
    background: hasValue ? theme.colors.brownLight : theme.colors.white,
}));

const RefinementPriceBox = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.space[2],
    alignItems: 'center',
    padding: `0 ${theme.space[3]}  ${theme.space[3]} ${theme.space[3]}`,

    [mq(0, 'sm')]: {
        padding: `${theme.space[3]} ${theme.space[4]}  ${theme.space[3]} ${theme.space[4]}`,
        marginBottom: theme.space[2],
    },
}));

const RefinementPriceInput = styled.label(({ theme }) => ({
    flexGrow: 1,
    '>input': {
        width: '100%',
        ...theme.mixins.useTextStyle('bodySmall'),
        borderRadius: theme.sizes.cornerRadius,
        border: `1px solid ${theme.colors.grey100}`,
        height: 40,
        paddingLeft: theme.space[2],
        paddingRight: theme.space[2],

        '::placeholder': {
            color: theme.colors.grey50,
        },
        ':focus-visible': {
            boxShadow: theme.shadows.accessibility,
        },
    },
}));

const RefinementPriceDash = styled.span(() => ({
    flexGrow: 0,
    display: 'flex',
    alignItems: 'center',
    '&:before': {
        content: '"—"',
    },
}));

export const RefinementPrice: FC<UseRangeProps> = (props: UseRangeProps) => {
    const { start, range, canRefine, refine } = useRange(props);
    const step = 1;
    const values = {
        min: start[0] !== -Infinity && start[0] !== range.min ? start[0] : unsetNumberInputValue,
        max: start[1] !== Infinity && start[1] !== range.max ? start[1] : unsetNumberInputValue,
    };
    const [prevValues, setPrevValues] = useState(values);

    const [{ from, to }, setRange] = useState({
        from: values.min?.toString(),
        to: values.max?.toString(),
    });

    if (values.min !== prevValues.min || values.max !== prevValues.max) {
        setRange({ from: values.min?.toString(), to: values.max?.toString() });
        setPrevValues(values);
    }

    function doRefine() {
        refine([from ? Number(from) : undefined, to ? Number(to) : undefined]);
    }

    useDebounce(
        () => {
            doRefine();
        },
        1000,
        [from, to],
    );

    if (!canRefine) return <></>;
    return (
        <RefinementPriceForm
            hasValue={!!from || !!to}
            name="refinePriceRange"
            onSubmit={(event) => {
                event.preventDefault();
                doRefine();
            }}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    event.currentTarget.requestSubmit();
                }
            }}
        >
            <RefinementPriceBox>
                <RefinementPriceInput>
                    <input
                        type="number"
                        min={range.min}
                        max={range.max}
                        inputMode="numeric"
                        value={stripLeadingZeroFromInput(from || unsetNumberInputValue)}
                        step={step}
                        placeholder={range.min?.toString()}
                        disabled={!canRefine}
                        onInput={({ currentTarget }) => {
                            const value = currentTarget.value;

                            setRange({ from: value || unsetNumberInputValue, to });
                        }}
                    />
                </RefinementPriceInput>
                <RefinementPriceDash />
                <RefinementPriceInput>
                    <input
                        type="number"
                        min={range.min}
                        max={range.max}
                        inputMode="numeric"
                        value={stripLeadingZeroFromInput(to || unsetNumberInputValue)}
                        step={step}
                        placeholder={range.max?.toString()}
                        disabled={!canRefine}
                        onInput={({ currentTarget }) => {
                            const value = currentTarget.value;

                            setRange({ from, to: value || unsetNumberInputValue });
                        }}
                    />
                </RefinementPriceInput>
            </RefinementPriceBox>
        </RefinementPriceForm>
    );
};

function stripLeadingZeroFromInput(value: string): string {
    return value.replace(/^(0+)\d/, (part) => Number(part).toString());
}
