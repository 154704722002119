import create from 'zustand';
type Banner = {
    isBannerOpen: boolean;
    setIsBannerOpen: (isBannerOpen: boolean) => void;

    headerHeight: number;
    setHeaderHeight: (height: number) => void;
};

export const useBannerState = create<Banner>((set, get) => ({
    isBannerOpen: true,
    setIsBannerOpen: (isBannerOpen) => {
        if (isBannerOpen === get().isBannerOpen) return;

        set({ isBannerOpen });
    },

    headerHeight: 0,
    setHeaderHeight: (height) => set({ headerHeight: height }),
}));
